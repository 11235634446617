import { getError } from '@/utils/helpers';
import StaffMemberService from '@/services/StaffMemberService';

export const namespaced = true;

function setPaginatedStaffMembers(commit, response) {
  commit('SET_STAFF_MEMBERS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setStaffMember(commit, response) {
  commit('SET_STAFF_MEMBER', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  staffMembers: [],
  staffMember: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_STAFF_MEMBERS(state, staffMembers) {
    state.staffMembers = staffMembers;
  },
  SET_STAFF_MEMBER(state, staffMember) {
    state.staffMember = staffMember;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  search({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    StaffMemberService.search(options)
      .then(response => {
        setPaginatedStaffMembers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  list({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    StaffMemberService.list(options)
      .then(response => {
        setPaginatedStaffMembers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    StaffMemberService.get(id)
      .then(response => {
        setStaffMember(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    return StaffMemberService.save(data.id, data.staffMember)
      .then(response => {
        setStaffMember(commit, response);
        setMessage(dispatch, 'Saved staff member');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return StaffMemberService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted staff member');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    StaffMemberService.paginate(link)
      .then(response => {
        setPaginatedStaffMembers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  staffMembers: state => {
    return state.staffMembers;
  },
  staffMember: state => {
    return state.staffMember;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

import { getError } from '@/utils/helpers';
import PaperworkGroupService from '@/services/PaperworkGroupService';

export const namespaced = true;

function setPaginatedPaperworkGroups(commit, response) {
  commit('SET_PAPERWORKGROUPS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setPaperworkGroup(commit, response) {
  commit('SET_PAPERWORKGROUP', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  paperworkGroups: [],
  paperworkGroup: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_PAPERWORKGROUPS(state, paperworkGroups) {
    state.paperworkGroups = paperworkGroups;
  },
  SET_PAPERWORKGROUP(state, paperworkGroup) {
    state.paperworkGroup = paperworkGroup;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  list({ dispatch, commit }, page) {
    commit('SET_LOADING', true);
    PaperworkGroupService.list(page)
      .then(response => {
        setPaginatedPaperworkGroups(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    PaperworkGroupService.get(params.id, params.group_id)
      .then(response => {
        setPaperworkGroup(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    PaperworkGroupService.save(data.id, data.paperworkGroup)
      .then(response => {
        setPaperworkGroup(commit, response);
        setMessage(dispatch, 'Saved paperwork set');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return PaperworkGroupService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted paperwork set');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    PaperworkGroupService.paginate(link)
      .then(response => {
        setPaginatedPaperworkGroups(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  paperworkGroups: state => {
    return state.paperworkGroups;
  },
  paperworkGroup: state => {
    return state.paperworkGroup;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

<template>
  <v-snackbar v-model="show" :color="snackbarLocal.colour">
    {{ snackbarLocal.message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="closeAction">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'Snackbar',
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('ui', ['snackbar']),
    snackbarLocal: function () {
      return { ...this.snackbar };
    }
  },
  watch: {
    snackbar: function () {
      this.show = this.snackbar.show;
    }
  },
  data: () => ({
    show: false
  }),
  methods: {
    closeAction() {
      store.dispatch('ui/snackbarClose');
    }
  }
};
</script>

import { getError } from '@/utils/helpers';
import SuburbService from '@/services/SuburbService';

export const namespaced = true;

function setSuburbs(commit, response) {
  if (response.data.data.length > 0) {
    commit('SET_ITEMS', response.data.data);
  }
  commit('SET_LOADING', false);
}

export const state = {
  loading: false,
  activeSuburb: null,
  items: [],
  error: null
};

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_ACTIVE_SUBURB(state, activeSuburb) {
    state.activeSuburb = activeSuburb;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

export const actions = {
  getSuburbsByNameOrPostCode({ commit }, name) {
    commit('SET_LOADING', true);
    SuburbService.getSuburbsByNameOrPostCode(name)
      .then(response => {
        setSuburbs(commit, response);
      })
      .catch(error => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  setActiveSuburb({ commit }, suburb) {
    commit('SET_ACTIVE_SUBURB', suburb);
  }
};

export const getters = {
  activeSuburb: state => {
    return state.activeSuburb;
  },
  items: state => {
    return state.items;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  }
};

import store from '@/store/index';

/**
 * Laravel permission check
 *
 * @param {string} permission
 * @returns boolean
 */
export const can = permission => {
  // check that we have a user
  const user = store.getters['auth/authUser'];
  if (!user) return false;

  // always return true if admin
  if (user.isAdmin) return true;

  // we need authorization to compare permissions
  if (!user.authorization) return false;

  // Get the user permissions and check that it is an array
  let _return = false;
  const permissions = user.authorization.permissions;
  if (!Array.isArray(permissions)) return _return;

  // Mimic the blade template can permission rules
  if (permission.includes('|')) {
    permission.split('|').forEach(function (item) {
      if (permissions.includes(item.trim())) {
        _return = true;
      }
    });
  } else if (permission.includes('&')) {
    _return = true;
    permission.split('&').forEach(function (item) {
      if (!permissions.includes(item.trim())) {
        _return = false;
      }
    });
  } else {
    _return = permissions.includes(permission.trim());
  }
  return _return;
};

/**
 * Laravel role check
 *
 * @param {string} role
 * @returns boolean
 */
export const is = role => {
  // check that we have a user
  const user = store.getters['auth/authUser'];
  if (!user) return false;

  // always return true if admin
  if (user.isAdmin) return true;

  // we need authorization to compare permissions
  if (!user.authorization) return false;

  // Get the user permissions and check that it is an array
  let _return = false;
  const roles = user.authorization.roles;
  if (!Array.isArray(roles)) return _return;

  // Mimic the blade template can permission rules
  if (role.includes('|')) {
    role.split('|').forEach(function (item) {
      if (roles.includes(item.trim())) {
        _return = true;
      }
    });
  } else if (role.includes('&')) {
    _return = true;
    role.split('&').forEach(function (item) {
      if (!roles.includes(item.trim())) {
        _return = false;
      }
    });
  } else {
    _return = roles.includes(role.trim());
  }
  return _return;
};

import * as API from '@/services/API';

export default {
  get(userId) {
    return API.apiClient.get(`/users/${userId}`);
  },
  list(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/users/${query}`);
  },
  paginate(link) {
    return API.apiClient.get(link);
  },
  async save(id, data) {
    if (id == 0) {
      return API.apiClient.post(`/users`, data);
    } else {
      return API.apiClient.put(`/users/${id}`, data);
    }
  },
  search(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/users/search${query}`);
  },
  async delete(id) {
    return API.apiClient.delete(`/users/${id}`);
  },
  async remove2fa(id) {
    return API.apiClient.delete(`/users/two-factor-authentication/${id}`);
  }
};

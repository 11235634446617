import { can } from '@/utils/permissions';

export default function permission({ to, next }) {
  if (to.meta.permission === undefined) {
    console.error('Permission middleware required permission to be set in metadata!');
  }
  if (can(to.meta.permission)) {
    next();
  } else {
    next({ name: 'notFound' });
  }
}

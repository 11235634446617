import { getError } from '@/utils/helpers';
import FlightService from '@/services/FlightService';

export const namespaced = true;

function setPaginatedFlights(commit, response) {
  commit('SET_FLIGHTS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setFlight(commit, response) {
  commit('SET_FLIGHT', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  flights: [],
  flight: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_FLIGHTS(state, flights) {
    state.flights = flights;
  },
  SET_FLIGHT(state, flight) {
    state.flight = flight;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  search({ dispatch, commit }, searchQuery) {
    commit('SET_LOADING', true);
    FlightService.search(searchQuery)
      .then(response => {
        setPaginatedFlights(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  list({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    FlightService.list(params)
      .then(response => {
        setPaginatedFlights(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    FlightService.get(params.id, params)
      .then(response => {
        setFlight(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    return FlightService.save(data.id, data.flight)
      .then(response => {
        setFlight(commit, response);
        setMessage(dispatch, 'Saved flight');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return FlightService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted flight');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    FlightService.paginate(link)
      .then(response => {
        setPaginatedFlights(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  flights: state => {
    return state.flights;
  },
  flight: state => {
    return state.flight;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

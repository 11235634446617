import { getError } from '@/utils/helpers';
import ItineraryItemService from '@/services/ItineraryItemService';

export const namespaced = true;

function setPaginatedItineraryItems(commit, response) {
  commit('SET_ITINERARY_ITEMS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setItineraryItem(commit, response) {
  commit('SET_ITINERARY_ITEM', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  itineraryItems: [],
  itineraryItem: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_ITINERARY_ITEMS(state, itineraryItems) {
    state.itineraryItems = itineraryItems;
  },
  SET_ITINERARY_ITEM(state, itineraryItem) {
    state.itineraryItem = itineraryItem;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  async createItineraryItemFromTemplate({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    return ItineraryItemService.createItineraryItemFromTemplate(params)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Itinerary items copied');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async copyItineraryItemsFromSubgroup({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    return ItineraryItemService.copyItineraryItemsFromSubgroup(params)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Itinerary items copied');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async generateWeekendsForSubgroup({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    return ItineraryItemService.generateWeekendsForSubgroup(params)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Itinerary weekends generated');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  list({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    ItineraryItemService.list(options)
      .then(response => {
        setPaginatedItineraryItems(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    ItineraryItemService.get(id)
      .then(response => {
        setItineraryItem(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    return ItineraryItemService.save(data.id, data.itineraryItem)
      .then(response => {
        setItineraryItem(commit, response);
        setMessage(dispatch, 'Saved itinerary item');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return ItineraryItemService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted itinerary item');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    ItineraryItemService.paginate(link)
      .then(response => {
        setPaginatedItineraryItems(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  itineraryItems: state => {
    return state.itineraryItems;
  },
  itineraryItem: state => {
    return state.itineraryItem;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

import { getError } from '@/utils/helpers';
import PersonService from '@/services/PersonService';

export const namespaced = true;

function setPaginatedPeople(commit, response) {
  commit('SET_PEOPLE', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setPerson(commit, response) {
  commit('SET_PERSON', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  people: [],
  person: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_PEOPLE(state, people) {
    state.people = people;
  },
  SET_PERSON(state, person) {
    state.person = person;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  search({ dispatch, commit }, searchQuery) {
    commit('SET_LOADING', true);
    PersonService.search(searchQuery)
      .then(response => {
        setPaginatedPeople(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  list({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    PersonService.list(options)
      .then(response => {
        setPaginatedPeople(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    PersonService.get(id)
      .then(response => {
        setPerson(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    await PersonService.save(data.id, data.person)
      .then(response => {
        setPerson(commit, response);
        setMessage(dispatch, 'Saved person');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    PersonService.paginate(link)
      .then(response => {
        setPaginatedPeople(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return PersonService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted person');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  people: state => {
    return state.people;
  },
  person: state => {
    return state.person;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

<template>
  <v-app>
    <v-main>
      <Loader />
      <Snackbar />
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
.v-application > .v-application--wrap > .container {
  max-width: 100%;
}
.v-application ul.v-breadcrumbs {
  margin: 0;
  padding: 0;
}
.v-data-table .v-data-footer__select {
  display: none;
}
.v-data-table .v-data-footer {
  min-height: 50px;
}
.v-data-table tr {
  cursor: pointer;
}
.v-data-table td.v-data-table__mobile-row {
  height: auto;
}

.v-data-table__mobile-row__wrapper {
  padding: 4px 0;
}

.toolbar-action .v-toolbar__content {
  padding-left: 0;
  margin-left: 0;
}

.v-main__wrap .container {
  max-width: none;
}
</style>

<script>
import Loader from '@/views/Loader';
import Snackbar from '@/views/Snackbar';
import { extend } from 'vee-validate';
import { required_if } from 'vee-validate/dist/rules';

// Custom vee-validate rules
extend('required_if_not', {
  ...required_if,
  // params: ['target']
  validate: (value, args) => {
    let target_value = args.target;
    return Boolean(target_value || value);
  },
  message: 'Bitte dieses Feld ausfüllen.'
});

export default {
  name: 'App',
  components: {
    Loader,
    Snackbar
  }
};
</script>

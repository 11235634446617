<template>
  <v-dialog v-model="loading" transition="false" fullscreen>
    <v-container fluid fill-height style="background-color: #eee">
      <v-layout justify-center align-center>
        <v-progress-circular indeterminate color="primary"> </v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loader',
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('ui', ['loading'])
  }
};
</script>

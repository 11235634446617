import { getError } from '@/utils/helpers';
import DesktopService from '@/services/DesktopService';

export const namespaced = true;

function setDesktop(commit, response) {
  commit('SET_DESKTOP', response.data);
  commit('SET_SUCCESS', true);
  commit('SET_LOADING', false);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  desktop: {},
  meta: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_DESKTOP(state, desktop) {
    state.desktop = desktop;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  getDesktop({ dispatch, commit }) {
    commit('SET_LOADING', true);
    DesktopService.getDesktop()
      .then(response => {
        setDesktop(commit, response);
        setMessage(dispatch, 'Desktop loaded');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  desktop: state => {
    return state.desktop;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

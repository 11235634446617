import { getError } from '@/utils/helpers';
import UserService from '@/services/UserService';

export const namespaced = true;

function setPaginatedUsers(commit, response) {
  commit('SET_USERS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  commit('SET_LOADING', false);
}

function setUser(commit, response) {
  commit('SET_USER', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LOADING', false);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  users: [],
  user: {
    first_name: '',
    surname: '',
    email: ''
  },
  meta: null,
  links: null,
  loading: false,
  error: null
};

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

export const actions = {
  search({ dispatch, commit }, searchQuery) {
    commit('SET_LOADING', true);
    UserService.search(searchQuery)
      .then(response => {
        setPaginatedUsers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  list({ dispatch, commit }, page) {
    commit('SET_LOADING', true);
    UserService.list(page)
      .then(response => {
        setPaginatedUsers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    UserService.get(id)
      .then(response => {
        setUser(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    UserService.paginate(link)
      .then(response => {
        setPaginatedUsers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    return UserService.save(data.id, data.group)
      .then(response => {
        setUser(commit, response);
        setMessage(dispatch, 'Saved group');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return UserService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted group');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async remove2fa({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return UserService.remove2fa(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Removed 2fa group');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  users: state => {
    return state.users;
  },
  user: state => {
    return state.user;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  }
};

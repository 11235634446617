import { getError } from '@/utils/helpers';
import StateService from '@/services/StateService';

export const namespaced = true;

function setStates(commit, response) {
  if (response.data.data.length > 0) {
    commit('SET_STATES', response.data.data);
  }
  commit('SET_LOADING', false);
}

export const state = {
  loading: false,
  states: [],
  error: null
};

export const mutations = {
  SET_STATES(state, states) {
    state.states = states;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

export const actions = {
  getStatesByCountry({ commit }, country) {
    commit('SET_LOADING', true);
    StateService.getStatesByCountry(country)
      .then(response => {
        setStates(commit, response);
      })
      .catch(error => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  }
};

export const getters = {
  states: state => {
    return state.states;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  }
};

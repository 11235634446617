export const namespaced = true;

export const state = {
  spotlightOpen: false,
  loading: false,
  error: null,
  snackbar: {
    show: false,
    colour: 'green',
    message: ''
  }
};

export const mutations = {
  SET_SNACKBAR_MESSAGE(state, props) {
    state.snackbar = props;
  },
  SET_SPOTLIGHT_OPEN(state, open) {
    state.spotlightOpen = open;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

export const actions = {
  toggleSpotlight({ commit }, open) {
    commit('SET_SPOTLIGHT_OPEN', open);
  },
  snackbarSuccess({ commit }, message) {
    commit('SET_SNACKBAR_MESSAGE', {
      show: true,
      message: message,
      colour: 'green'
    });
  },
  snackbarError({ commit }, message) {
    commit('SET_SNACKBAR_MESSAGE', {
      show: true,
      message: message,
      colour: 'red'
    });
  },
  snackbarClose({ commit }) {
    commit('SET_SNACKBAR_MESSAGE', {
      show: false,
      message: '',
      colour: 'green'
    });
  },
  loading({ commit }, loading) {
    commit('SET_LOADING', loading);
  }
};

export const getters = {
  snackbar: state => {
    return state.snackbar;
  },
  spotlightOpen: state => {
    return state.spotlightOpen;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  }
};

import { getError } from '@/utils/helpers';
import FamilyMemberService from '@/services/FamilyMemberService';

export const namespaced = true;

function setPaginatedFamilyMembers(commit, response) {
  commit('SET_FAMILY_MEMBERS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setFamilyMember(commit, response) {
  commit('SET_FAMILY_MEMBER', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  familyMembers: [],
  familyMember: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_FAMILY_MEMBERS(state, familyMembers) {
    state.familyMembers = familyMembers;
  },
  SET_FAMILY_MEMBER(state, familyMember) {
    state.familyMember = familyMember;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  search({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    FamilyMemberService.search(options)
      .then(response => {
        setPaginatedFamilyMembers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  list({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    FamilyMemberService.list(options)
      .then(response => {
        setPaginatedFamilyMembers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    FamilyMemberService.get(id)
      .then(response => {
        setFamilyMember(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    return FamilyMemberService.save(data.id, data.familyMember)
      .then(response => {
        setFamilyMember(commit, response);
        setMessage(dispatch, 'Saved family member');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return FamilyMemberService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted family member');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    FamilyMemberService.paginate(link)
      .then(response => {
        setPaginatedFamilyMembers(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  familyMembers: state => {
    return state.familyMembers;
  },
  familyMember: state => {
    return state.familyMember;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

import { getError } from '@/utils/helpers';
import PaperworkService from '@/services/PaperworkService';

export const namespaced = true;

function setPaginatedPaperworks(commit, response) {
  commit('SET_PAPERWORKS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setPaperwork(commit, response) {
  commit('SET_PAPERWORK', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  paperworks: [],
  paperwork: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: false
};

export const mutations = {
  SET_PAPERWORKS(state, paperworks) {
    state.paperworks = paperworks;
  },
  SET_PAPERWORK(state, paperwork) {
    state.paperwork = paperwork;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  async createPaperworkFromPaperworkSet({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    return PaperworkService.createPaperworkFromPaperworkSet(params.subgroup_id, params.paperwork_group_id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Paperwork set added');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  list({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    PaperworkService.list(options)
      .then(response => {
        setPaginatedPaperworks(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    PaperworkService.get(id)
      .then(response => {
        setPaperwork(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    return PaperworkService.save(data.id, data.paperwork)
      .then(response => {
        setPaperwork(commit, response);
        setMessage(dispatch, 'Saved paperwork');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return PaperworkService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted paperwork');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    PaperworkService.paginate(link)
      .then(response => {
        setPaginatedPaperworks(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  paperworks: state => {
    return state.paperworks;
  },
  paperwork: state => {
    return state.paperwork;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

import { getError } from '@/utils/helpers';
import PaperworkSetService from '@/services/PaperworkSetService';

export const namespaced = true;

function setPaginatedPaperworkSets(commit, response) {
  commit('SET_PAPERWORKSETS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setPaperworkSet(commit, response) {
  commit('SET_PAPERWORKSET', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  paperworkSets: [],
  paperworkSet: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_PAPERWORKSETS(state, paperworkSets) {
    state.paperworkSets = paperworkSets;
  },
  SET_PAPERWORKSET(state, paperworkSet) {
    state.paperworkSet = paperworkSet;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  list({ dispatch, commit }, page) {
    commit('SET_LOADING', true);
    PaperworkSetService.list(page)
      .then(response => {
        setPaginatedPaperworkSets(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, params) {
    commit('SET_LOADING', true);
    PaperworkSetService.get(params.id, params.group_id)
      .then(response => {
        setPaperworkSet(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    PaperworkSetService.save(data.id, data.paperworkset)
      .then(response => {
        setPaperworkSet(commit, response);
        setMessage(dispatch, 'Saved paperwork set');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return PaperworkSetService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted paperwork set');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    PaperworkSetService.paginate(link)
      .then(response => {
        setPaginatedPaperworkSets(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  paperworkSets: state => {
    return state.paperworkSets;
  },
  paperworkSet: state => {
    return state.paperworkSet;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

import * as API from '@/services/API';

export default {
  list(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/paperwork-group/${query}`);
  },
  get(id) {
    return API.apiClient.get(`/paperwork-group/${id}`);
  },
  async save(id, data) {
    if (id == 0) {
      return API.apiClient.post(`/paperwork-group`, data);
    } else {
      return API.apiClient.put(`/paperwork-group/${id}`, data);
    }
  },
  paginate(link) {
    return API.apiClient.get(link);
  },
  async delete(id) {
    return API.apiClient.delete(`/paperwork-group/${id}`);
  }
};

import Vue from 'vue';
import Vuex from 'vuex';

import * as auth from '@/store/modules/Auth';
import * as desktop from '@/store/modules/Desktop';
import * as family from '@/store/modules/FamilyMember';
import * as flight from '@/store/modules/Flight';
import * as group from '@/store/modules/Group';
import * as itineraryItem from '@/store/modules/ItineraryItem';
import * as message from '@/store/modules/Message';
import * as namelist from '@/store/modules/Namelist';
import * as organisation from '@/store/modules/Organisation';
import * as paperwork from '@/store/modules/Paperwork';
import * as paperworkgroup from '@/store/modules/Paperworkgroup';
import * as paperworkset from '@/store/modules/Paperworkset';
import * as person from '@/store/modules/Person';
import * as staffMember from '@/store/modules/StaffMember';
import * as subgroup from '@/store/modules/Subgroup';
import * as suburb from '@/store/modules/Suburb';
import * as state from '@/store/modules/State';
import * as report from '@/store/modules/Report';
import * as ui from '@/store/modules/Ui';
import * as user from '@/store/modules/User';
import * as userGroup from '@/store/modules/UserGroup';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    auth,
    desktop,
    family,
    flight,
    group,
    itineraryItem,
    message,
    namelist,
    organisation,
    paperwork,
    paperworkgroup,
    paperworkset,
    person,
    staffMember,
    subgroup,
    suburb,
    state,
    report,
    ui,
    user,
    userGroup
  }
});

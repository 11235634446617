import { getError } from '@/utils/helpers';
import NamelistService from '@/services/NamelistService';

export const namespaced = true;

function setPaginatedNamelists(commit, response) {
  commit('SET_NAMELISTS', response.data.data);
  commit('SET_META', response.data.meta);
  commit('SET_LINKS', response.data.links);
  setSuccess(commit);
}

function setNamelist(commit, response) {
  commit('SET_NAMELIST', response.data.data);
  commit('SET_META', response.data.meta);
  setSuccess(commit);
}

function setSuccess(commit) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', true);
}

function setMessage(dispatch, message) {
  dispatch('ui/snackbarSuccess', message, { root: true });
}

function setError(dispatch, commit, error) {
  commit('SET_LOADING', false);
  commit('SET_SUCCESS', false);
  commit('SET_ERROR', error);
  dispatch('ui/snackbarError', error, { root: true });
}

export const state = {
  namelists: [],
  namelist: {},
  meta: null,
  links: null,
  loading: false,
  error: null,
  success: null
};

export const mutations = {
  SET_NAMELISTS(state, namelists) {
    state.namelists = namelists;
  },
  SET_NAMELIST(state, namelist) {
    state.namelist = namelist;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  }
};

export const actions = {
  list({ dispatch, commit }, options) {
    commit('SET_LOADING', true);
    NamelistService.list(options)
      .then(response => {
        setPaginatedNamelists(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  get({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    NamelistService.get(id)
      .then(response => {
        setNamelist(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async save({ dispatch, commit }, data) {
    commit('SET_LOADING', true);
    return NamelistService.save(data.id, data.namelist)
      .then(response => {
        setNamelist(commit, response);
        setMessage(dispatch, 'Saved namelist');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  async delete({ dispatch, commit }, id) {
    commit('SET_LOADING', true);
    return NamelistService.delete(id)
      .then(() => {
        setSuccess(commit);
        setMessage(dispatch, 'Deleted namelist');
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  },
  paginate({ dispatch, commit }, link) {
    commit('SET_LOADING', true);
    NamelistService.paginate(link)
      .then(response => {
        setPaginatedNamelists(commit, response);
      })
      .catch(error => {
        setError(dispatch, commit, getError(error));
      });
  }
};

export const getters = {
  namelists: state => {
    return state.namelists;
  },
  namelist: state => {
    return state.namelist;
  },
  meta: state => {
    return state.meta;
  },
  links: state => {
    return state.links;
  },
  loading: state => {
    return state.loading;
  },
  error: state => {
    return state.error;
  },
  success: state => {
    return state.success;
  }
};

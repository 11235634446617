import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { localize } from 'vee-validate';
import { mapGetters } from 'vuex';

Vue.config.productionTip = false;

// Check if this is the demo version for stating
Vue.prototype.$testMode = process.env.VUE_APP_TEST_MODE;

/**
 * Set all error messages globally as the default error messages for vee-validate
 * are not very good.
 */
localize({
  en: {
    messages: {
      required: 'This field is required',
      min: 'This field must have no less than {length} characters',
      max: (_, { length }) => `This field must have no more than ${length} characters`
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  methods: {
    escapeKeyListener: function (event) {
      if (event.ctrlKey && event.keyCode == 83) {
        store.dispatch('ui/toggleSpotlight', this.spotlightOpen ? false : true);
      }
    }
  },
  computed: {
    ...mapGetters('ui', ['spotlightOpen'])
  },
  created: function () {
    document.addEventListener('keyup', this.escapeKeyListener);
  },
  destroyed: function () {
    document.removeEventListener('keyup', this.escapeKeyListener);
  },
  errorCaptured: function (err) {
    alert(err.message);
    return false;
  },
  render: h => h(App)
}).$mount('#app');
